import { slideTo, status } from '../lib';
import Slide from './base';
import EmailSlide from './email';
import GoogleSlide from './sns/google';
import NaverSlide from './sns/naver';
import KakaoSlide from './sns/kakao';
import AppleSlide from './sns/apple';
import FacebookSlide from './sns/facebook';

export default class SelectSlide extends Slide {
  static slideName = "select";
  static navType = "close";

  static refresh = () => {
    window.oAuthErrorHandler = undefined;
  }

  static init = () => {
    status.user = {};
    status.hideNav = false;

    this.slide.querySelector('.platform-btn.email').addEventListener('click', e => {
      slideTo(EmailSlide);
      // initSlide();
    });

    function snsSlide(of) {
      switch(of) {
        case 'naver':
          return NaverSlide;
        case 'kakao':
          return KakaoSlide;
        case 'google':
          return GoogleSlide;
        case 'apple':
          return AppleSlide;
        case 'facebook':
          return FacebookSlide;
      }
    }

    const disableSNS = {
      'all': [
        // 'apple',
        // 'facebook',
        // 'google',
        // 'kakao',
        // 'naver'
      ],
      'iOS': [
        // 'facebook',
        // 'google',
        // 'naver',
      ],
      'Android': [
        // 'facebook',
        // 'google',
        // 'kakao',
        // 'apple',
        // 'naver',
      ]
    }

    // Disable for Old iOS
    if (window.webkit?.messageHandlers?.setToken && !window.webkit?.messageHandlers?.oAuth) {
      this.slide.querySelectorAll('.platform-btn.sns').forEach(e => {
        [
          'google',
          'naver',
          'kakao',
          // 'facebook',
          // 'apple'
        ].forEach(s => {
          if (e.classList.contains(s)) {
            e.classList.add('disabled');
            e.classList.add('ios-update');
          }
        })
      });
    }

    // Disable for Old Android
    if (this.native == 'Android' && !window.android?.oAuth) {
      this.slide.querySelectorAll('.platform-btn.sns').forEach(e => {
        [
          'google',
          'naver',
          'kakao',
          // 'facebook',
          // 'apple'
        ].forEach(s => {
          if (e.classList.contains(s)) {
            e.classList.add('disabled');
            e.classList.add('android-update');
          }
        })
      });
    }

    switch (this.native) {
      case 'iOS':
        this.slide.querySelectorAll('.platform-btn.sns').forEach(e => {
          disableSNS[this.native].forEach(s => {
            if (e.classList.contains(s))
              e.classList.add('disabled');
          });
        });
        break;

      case 'Android':
        this.slide.querySelectorAll('.platform-btn.sns').forEach(e => {
          disableSNS[this.native].forEach(s => {
            if (e.classList.contains(s))
              e.classList.add('disabled');
          });
        });
        break;
    }

    this.slide.querySelectorAll('.platform-btn.sns').forEach(e => {
      disableSNS['all'].forEach(s => {
        if (e.classList.contains(s))
          e.classList.add('disabled');
      });
    });

    this.slide.querySelectorAll('.platform-btn.sns:not(.disabled)').forEach(e => {
      e.addEventListener('click', evt => {
        if (!e.classList.contains('disabled'))
          slideTo(snsSlide(e.dataset.type));
      });
    });

    this.slide.querySelectorAll('.platform-btn.sns.disabled').forEach(e => {
      e.addEventListener('click', evt => {
        if (e.classList.contains('disabled')) {
          if (e.classList.contains('ios-update') || e.classList.contains('android-update'))
            alert("앱을 최신버전으로 업데이트 해주세요!");
          else
            alert("앱 로그인 준비 중입니다.");
        }
      });
    });
  }
}